import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, Order, Category } from 'src/app/app.models'; 
import { AppSettings } from 'src/app/app.settings'; 
import { environment } from 'src/environments/environment';   
import { ConfirmDialogComponent, ConfirmDialogModel } from './shared/confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from './shared/alert-dialog/alert-dialog.component';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
const httpOptions = {
  headers: new HttpHeaders({
    "basic": environment.basic
  }),
  //params:null
};
export class Data {
  constructor(public categories: Category[],
              public userData: any[],
              public stores: any[],
              public cartList: MenuItem[],
              public orderList: Order[],
              public favorites: MenuItem[], 
              public totalPrice: number,
              public totalCartCount: number
              ) {
                
               }
}
export class UserData {
  constructor(public orders: any[],
              public profile: any
              ) {
                
               }
}
@Injectable({
  providedIn: 'root'
})
export class AppService {
  public Data = new Data(
    [],  // categories 
    [],
    [],  // stores 
    [],  // cartList
    [],  // orderList
    [],  // favorites 
    0, // totalPrice
    0 //totalCartCount
  )  
  public UserData = new UserData(
    [],  // orders
    [],  //profile information
  ) 
  public url = environment.url + '/assets/data/';
  public baseUrl = environment.url;
  public mediaURL = environment.mediaURL; 
  isPrinting: boolean | undefined;
  
  constructor(public http:HttpClient, 
              private datePipe:DatePipe,
              public router: Router,
              private bottomSheet: MatBottomSheet, 
              private snackBar: MatSnackBar,
              public dialog: MatDialog,
              public appSettings:AppSettings,
              public translateService: TranslateService) {
                let userData = localStorage.getItem('userData');
                  if (userData && userData != null && userData !== 'null') {
                    this.UserData = JSON.parse(userData);
                  }else{
                    this.UserData =new UserData([], []);
                  }
               }  
//-------------------------------------------------Login----------------------------------------//
  public adminLogin(input:any){
    const httpOptions = {
      headers: new HttpHeaders({
        "basic": environment.basic
      })
    };
    return this.http.post(environment.adminURL + 'cheflogin', input,httpOptions)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }
  //--------------------------------------------Login----------------------------------------------//

  //--------------------------------------------Products----------------------------------------------//
  public getProducts(input : any): Observable<any>{
    return this.http.post(environment.adminURL + 'products', input,httpOptions)
  }
   public profile(input:any){
    const httpOptions = {
      headers: new HttpHeaders({
        "basic": environment.basic
      })
    };
    return this.http.post(environment.baseURL + 'shef/edit_profile', input,httpOptions)
      .pipe(
        map(
          (response:any) => {
            if (response) {
			  console.log('response is');
			  console.log(response);
			  response.data.avatar = response.data.avatar!=''?environment.mediaURL+response.data.avatar:'';
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }
  public productById(id:number): Observable<any>{
    return this.http.get<any[]>(environment.adminURL + 'products/'+id,httpOptions);
  }
//--------------------------------------------Products----------------------------------------------//


//--------------------------------------------Chef----------------------------------------------//
 public registerChef(input:any){
  const httpOptions = {
    headers: new HttpHeaders({
      "basic": environment.basic
    })
  };
  return this.http.post(environment.adminURL + 'chef/register', input,httpOptions)
    .pipe(
      map(
        (response:any) => {
          if (response) {
            return response;
          }
        },
        (error: any) => {
          return error;
        }
      )
    )
}
public updateChef(input:any){
  const httpOptions = {
    headers: new HttpHeaders({
      "basic": environment.basic
    })
  };
  return this.http.post(environment.adminURL + 'rider/update', input,httpOptions)
    .pipe(
      map(
        (response:any) => {
          if (response) {
            return response;
          }
        },
        (error: any) => {
          return error;
        }
      )
    )
}
//--------------------------------------------chef----------------------------------------------//
//--------------------------------------------rider----------------------------------------------//

public registerRider(input: any) {
  const httpOptions = {
      headers: new HttpHeaders({
          "basic": environment.basic
      })
  };
  return this.http.post(environment.adminURL + 'rider/register', input, httpOptions)
      .pipe(
          map(
              (response: any) => {
                  if (response) {
                      return response;
                  }
              },
              (error: any) => {
                  return error;
              }
          )
      )
}
public updateRider(input: any) {
  const httpOptions = {
      headers: new HttpHeaders({
          "basic": environment.basic
      })
  };
  return this.http.post(environment.adminURL + 'rider/update', input, httpOptions)
      .pipe(
          map(
              (response: any) => {
                  if (response) {
                      return response;
                  }
              },
              (error: any) => {
                  return error;
              }
          )
      )
}

public getRiders() {
  return this.http.get<any[]>(environment.adminURL + 'riders',  httpOptions);
}
//--------------------------------------------rider----------------------------------------------//
public addAddress(input:any){
  const httpOptions = {
    headers: new HttpHeaders({
      "basic": environment.basic
    })
  };
  return this.http.post(environment.baseURL + 'address/save', input,httpOptions)
    .pipe(
      map(
        (response:any) => {
          if (response) {
            return response;
          }
        },
        (error: any) => {
          return error;
        }
      )
    )
}
public editAddress(input:any){
  const httpOptions = {
    headers: new HttpHeaders({
      "basic": environment.basic
    })
  };
  return this.http.post(environment.baseURL + 'address/edit', input,httpOptions)
    .pipe(
      map(
        (response:any) => {
          if (response) {
            return response;
          }
        },
        (error: any) => {
          return error;
        }
      )
    )
}

  public getUsers(input :any) {
    return this.http.post(environment.adminURL + 'users/getByRole', input,httpOptions)
    .pipe(
        map(
        (response:any) => {
            if (response) {
            return response;
            }
        },
        (error: any) => {
            return error;
        }
        )
    )
  }
  
  public updateProfile(input:any){
    const httpOptions = {
      headers: new HttpHeaders({
        "basic": environment.basic
      })
    };
    return this.http.post(environment.baseURL + 'shef/update_profile', input,httpOptions)
      .pipe(
        map(
          (response:any) => {
            if (response) {
			  response.data.avatar = response.data.avatar!=''?environment.mediaURL+response.data.avatar:'';
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }

  public assignRider(input: any){
    const httpOptions = {
      headers: new HttpHeaders({
        "basic": environment.basic
      })
    };
    
    return this.http.post(environment.adminURL + 'order/assign_rider', input,httpOptions)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
    //return this.http.get<any[]>(this.url + 'orders.json');
  }
  
  public getMenuItems(): Observable<MenuItem[]>{
    return this.http.get<MenuItem[]>(this.url + 'menu-items.json');
  } 
 
  public getMenuItemById(id:number): Observable<MenuItem>{
    return this.http.get<MenuItem>(this.url + 'menu-item-' + id + '.json');
  }
 
  public getSpecialMenuItems(): Observable<MenuItem[]>{
    return this.http.get<MenuItem[]>(this.url + 'special-menu-items.json');
  } 

  public getBestMenuItems(): Observable<MenuItem[]>{
    return this.http.get<MenuItem[]>(this.url + 'best-menu-items.json');
  } 


  public getIngredients(): Observable<any[]>{
    return this.http.get<any[]>(environment.adminURL + 'ingredients',httpOptions);
  }
  public saveIngredient(input:any){
    return this.http.post(environment.adminURL + 'ingredient/create', input,httpOptions)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }
  public updateIngredient(input:any){
    return this.http.post(environment.adminURL + 'ingredient/update', input,httpOptions)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }
  public deleteIngredient(input:any){
    return this.http.post(environment.adminURL + 'ingredient/delete', input,httpOptions)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }

  public getCusines(): Observable<any[]>{
    return this.http.get<any[]>(environment.adminURL + 'cuisines',httpOptions);
  }
  public saveCuisine(input:any){
    return this.http.post(environment.adminURL + 'cuisine/create', input,httpOptions)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }
  public updateCuisine(input:any){
    return this.http.post(environment.adminURL + 'cuisine/update', input,httpOptions)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }



  public getCategories(): Observable<any[]>{
    return this.http.get<any[]>(environment.adminURL + 'food_types',httpOptions);
  } 

  public saveFoodType(input:any){
    return this.http.post(environment.adminURL + 'food_types/create', input,httpOptions)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }
  public updateFoodType(input:any){
    return this.http.post(environment.adminURL + 'food_types/edit', input,httpOptions)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }
  public getStores(): Observable<Category[]>{
    return this.http.get<any[]>(environment.adminURL + 'storelocators');
  }
  public getHomeCarouselSlides(){
    const input = {
      postal_code: 500018
    };
    return this.http.post(environment.baseURL + 'home/banners', input)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }
  public getStock(id:any): Observable<any[]>{
    return this.http.get<any[]>(environment.adminURL + 'stock/'+id);
  }

  public getFoodTags(): Observable<any> {
    return this.http.get<any[]>(environment.baseURL+'food_tags');
  }
  /*
  public getFoodTags() {
    return this.http.get(environment.baseURL+'food_tags').pipe(map((data: any) => data.data.food_tags ));
   }
  */
  public getFoodTypes(): Observable<any> {
    return this.http.get<any[]>(environment.baseURL+'food_types');
  }


  public getUnits(): Observable<any> {
    return this.http.get<any[]>(environment.baseURL+'size_units');
  }

  public getShefs(): Observable<any> {
    return this.http.get<any[]>(environment.baseURL+'chefs');
  }

  public saveChefItem(input: any) {
    return this.http.post(environment.baseURL+'save_chef_item', input)
    .pipe(
      map(
        (response:any) => {
          if (response) {
            return response;
          }
        },
        (error: any) => {
          return error;
        }
      )
    )
  }

  public getStockk(id:any,input :any){
    return this.http.post(environment.adminURL + 'stockk/'+id, input)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }
  public businessStock(input :any){
    return this.http.post(environment.adminURL + 'business_stock', input)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }
  public storeStock(input :any){
    return this.http.post(environment.adminURL + 'store_stock', input)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }
  public updateStock(input :any){
    return this.http.post(environment.adminURL + 'stock_update', input)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }
  public TransferStock(input :any){
    return this.http.post(environment.adminURL + 'transfer_stock', input)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }
  public getReservations(){
    return this.http.get<any[]>(this.url + 'reservations.json');
  }
  public orderInvoice(id:any){
    const headers = new HttpHeaders().set('Content-Type', 'html/plain; charset=utf-8');
    return this.http.get(environment.adminURL + 'print-invoice/'+id,{ headers, responseType: 'text'});
  }
  public getOrders(input: any){
    const httpOptions = {
      headers: new HttpHeaders({
        "basic": environment.basic
      })
    };
    
    return this.http.post(environment.adminURL + 'ordersByCid', input,httpOptions)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
    //return this.http.get<any[]>(this.url + 'orders.json');
  }
  public updateStatus(input: any){
    const httpOptions = {
      headers: new HttpHeaders({
        "basic": environment.basic
      })
    };
    
    return this.http.post(environment.adminURL + 'orderStatus', input,httpOptions)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
    //return this.http.get<any[]>(this.url + 'orders.json');
  }
  public getGUID(){
    let guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
        return v.toString(16);
    }).toLowerCase();
    return guid;
  }

  public addToCart(menuItem:MenuItem, component:any){   
    if(!this.Data.cartList.find(item=>item.id == menuItem.id)){
      menuItem.cartCount = (menuItem.cartCount) ? menuItem.cartCount : 1;
      this.Data.cartList.push(menuItem); 
      this.calculateCartTotal(); 
      if(component){
        this.openCart(component);        
      }
      else{ 
        this.snackBar.open('The menu item "' + menuItem.name + '" has been added to cart.', '×', {
          verticalPosition: 'top',
          duration: 3000,
          direction: (this.appSettings.settings.rtl) ? 'rtl':'ltr',
          panelClass: ['success'] 
        });
      }
    }  
  } 

  public openCart(component:any){  
    this.bottomSheet.open(component, {
      direction: (this.appSettings.settings.rtl) ? 'rtl':'ltr'
    }).afterDismissed().subscribe(isRedirect=>{  
      if(isRedirect){ 
        window.scrollTo(0,0);  
      }        
    });  
  }

  public calculateCartTotal(){
    this.Data.totalPrice = 0;
    this.Data.totalCartCount = 0;
    this.Data.cartList.forEach(item=>{
      let price = 0;
      if(item.discount){
        price = item.price - (item.price * (item.discount / 100));
      }
      else{
        price = item.price;
      }
      this.Data.totalPrice = this.Data.totalPrice + (price * item.cartCount); 
      this.Data.totalCartCount = this.Data.totalCartCount + item.cartCount;  
    });
  }

  public addToFavorites(menuItem:MenuItem){
    let message:string, status:string;
    if(this.Data.favorites.find(item=>item.id == menuItem.id)){ 
      message = 'The menu item "' + menuItem.name + '" already added to favorites.'; 
      status = 'error';    
    } 
    else{
      this.Data.favorites.push(menuItem);
      message = 'The menu item "' + menuItem.name + '" has been added to favorites.'; 
      status = 'success';  
    } 
    this.snackBar.open(message, '×', {
      verticalPosition: 'top',
      duration: 3000,
      direction: (this.appSettings.settings.rtl) ? 'rtl':'ltr',
      panelClass: [status] 
    });   
  }

  public openDialog(component:any, data:any, panelClass:any){ 
    return this.dialog.open(component, {
      data: data, 
      panelClass: panelClass,
      autoFocus: false,
      direction: (this.appSettings.settings.rtl) ? 'rtl':'ltr'
    });  
  }

  public openConfirmDialog(title:string, message:string) {  
    const dialogData = new ConfirmDialogModel(title, message); 
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    }); 
    return dialogRef; 
  }

  public openAlertDialog(message:string) {   
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      maxWidth: "400px",
      data: message
    }); 
    return dialogRef; 
  }

  public makeReservation(dialogComponent:any, data:any, onDialog:boolean = false){
    if(onDialog){
      const dialogRef = this.openDialog(dialogComponent, null, 'theme-dialog');
      dialogRef.afterClosed().subscribe(data => {  
        if(data){ 
          this.showReservationMessage(data);
        } 
      }); 
    }
    else{
      this.showReservationMessage(data);
    } 
  }
  private showReservationMessage(data:any){
    this.snackBar.open('Dear ' + data.fullName + ', thank you for your reservation! Your reservation at Popino on the '+ this.datePipe.transform( data.date,'dd-MM-yyyy') +' at '+ data.time +' for '+ data.guests +' people will review by our team and someone will be in touch soon.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 9000 });
  } 

  public getTranslateValue(key:string, param:string = ''){  
    let value = null;
    this.translateService.get(key, { param: param }).subscribe((res: string) => {
      value = res;
    }) 
    return value; 
  } 

  public filterData(data:any, categoryId:number, sort?:string, page?:number, perPage?:number){  
    if(categoryId){
      data = data.filter((item:any) => item.categoryId == categoryId);
    }   

    //for show more properties mock data 
    // for (var index = 0; index < 2; index++) {
    //   data = data.concat(data);        
    // }     
     
    this.sortData(sort, data);
    return this.paginator(data, page, perPage)
  }

  public sortData(sort?:string, data?:any){
    if(sort){
      switch (sort) { 
        case 'Popular':
          data = data.sort((a:any, b:any) => { 
            if(a.ratingsValue/a.ratingsCount < b.ratingsValue/b.ratingsCount){
              return 1;
            }
            if(a.ratingsValue/a.ratingsCount > b.ratingsValue/b.ratingsCount){
              return -1;
            }
            return 0; 
          });
          break;
        case 'Price (Low to High)': 
          data = data.sort((a:any,b:any) => {
            if(a.price > b.price){
              return 1;
            }
            if(a.price < b.price){
              return -1;
            }
            return 0;  
          });
          break;
        case 'Price (High to Low)': 
          data = data.sort((a:any,b:any) => {
            if(a.price < b.price){
              return 1;
            }
            if(a.price > b.price){
              return -1;
            }
            return 0;  
          }); 
          break;
        default:
          break;
      }
    }
    return data;
  }

  public paginator(items:any, page?:any, perPage?:any) { 
    var page = page || 1,
    perPage = perPage || 4,
    offset = (page - 1) * perPage,   
    paginatedItems = items.slice(offset).slice(0, perPage),
    totalPages = Math.ceil(items.length / perPage);
    return {
      data: paginatedItems,
      pagination:{
        page: page,
        perPage: perPage,
        prePage: page - 1 ? page - 1 : null,
        nextPage: (totalPages > page) ? page + 1 : null,
        total: items.length,
        totalPages: totalPages,
      }
    };
  }  

  public getCountries(){
    return [ 
        {name: 'Afghanistan', code: 'AF'}, 
        {name: 'Aland Islands', code: 'AX'}, 
        {name: 'Albania', code: 'AL'}, 
        {name: 'Algeria', code: 'DZ'}, 
        {name: 'American Samoa', code: 'AS'}, 
        {name: 'AndorrA', code: 'AD'}, 
        {name: 'Angola', code: 'AO'}, 
        {name: 'Anguilla', code: 'AI'}, 
        {name: 'Antarctica', code: 'AQ'}, 
        {name: 'Antigua and Barbuda', code: 'AG'}, 
        {name: 'Argentina', code: 'AR'}, 
        {name: 'Armenia', code: 'AM'}, 
        {name: 'Aruba', code: 'AW'}, 
        {name: 'Australia', code: 'AU'}, 
        {name: 'Austria', code: 'AT'}, 
        {name: 'Azerbaijan', code: 'AZ'}, 
        {name: 'Bahamas', code: 'BS'}, 
        {name: 'Bahrain', code: 'BH'}, 
        {name: 'Bangladesh', code: 'BD'}, 
        {name: 'Barbados', code: 'BB'}, 
        {name: 'Belarus', code: 'BY'}, 
        {name: 'Belgium', code: 'BE'}, 
        {name: 'Belize', code: 'BZ'}, 
        {name: 'Benin', code: 'BJ'}, 
        {name: 'Bermuda', code: 'BM'}, 
        {name: 'Bhutan', code: 'BT'}, 
        {name: 'Bolivia', code: 'BO'}, 
        {name: 'Bosnia and Herzegovina', code: 'BA'}, 
        {name: 'Botswana', code: 'BW'}, 
        {name: 'Bouvet Island', code: 'BV'}, 
        {name: 'Brazil', code: 'BR'}, 
        {name: 'British Indian Ocean Territory', code: 'IO'}, 
        {name: 'Brunei Darussalam', code: 'BN'}, 
        {name: 'Bulgaria', code: 'BG'}, 
        {name: 'Burkina Faso', code: 'BF'}, 
        {name: 'Burundi', code: 'BI'}, 
        {name: 'Cambodia', code: 'KH'}, 
        {name: 'Cameroon', code: 'CM'}, 
        {name: 'Canada', code: 'CA'}, 
        {name: 'Cape Verde', code: 'CV'}, 
        {name: 'Cayman Islands', code: 'KY'}, 
        {name: 'Central African Republic', code: 'CF'}, 
        {name: 'Chad', code: 'TD'}, 
        {name: 'Chile', code: 'CL'}, 
        {name: 'China', code: 'CN'}, 
        {name: 'Christmas Island', code: 'CX'}, 
        {name: 'Cocos (Keeling) Islands', code: 'CC'}, 
        {name: 'Colombia', code: 'CO'}, 
        {name: 'Comoros', code: 'KM'}, 
        {name: 'Congo', code: 'CG'}, 
        {name: 'Congo, The Democratic Republic of the', code: 'CD'}, 
        {name: 'Cook Islands', code: 'CK'}, 
        {name: 'Costa Rica', code: 'CR'}, 
        {name: 'Cote D\'Ivoire', code: 'CI'}, 
        {name: 'Croatia', code: 'HR'}, 
        {name: 'Cuba', code: 'CU'}, 
        {name: 'Cyprus', code: 'CY'}, 
        {name: 'Czech Republic', code: 'CZ'}, 
        {name: 'Denmark', code: 'DK'}, 
        {name: 'Djibouti', code: 'DJ'}, 
        {name: 'Dominica', code: 'DM'}, 
        {name: 'Dominican Republic', code: 'DO'}, 
        {name: 'Ecuador', code: 'EC'}, 
        {name: 'Egypt', code: 'EG'}, 
        {name: 'El Salvador', code: 'SV'}, 
        {name: 'Equatorial Guinea', code: 'GQ'}, 
        {name: 'Eritrea', code: 'ER'}, 
        {name: 'Estonia', code: 'EE'}, 
        {name: 'Ethiopia', code: 'ET'}, 
        {name: 'Falkland Islands (Malvinas)', code: 'FK'}, 
        {name: 'Faroe Islands', code: 'FO'}, 
        {name: 'Fiji', code: 'FJ'}, 
        {name: 'Finland', code: 'FI'}, 
        {name: 'France', code: 'FR'}, 
        {name: 'French Guiana', code: 'GF'}, 
        {name: 'French Polynesia', code: 'PF'}, 
        {name: 'French Southern Territories', code: 'TF'}, 
        {name: 'Gabon', code: 'GA'}, 
        {name: 'Gambia', code: 'GM'}, 
        {name: 'Georgia', code: 'GE'}, 
        {name: 'Germany', code: 'DE'}, 
        {name: 'Ghana', code: 'GH'}, 
        {name: 'Gibraltar', code: 'GI'}, 
        {name: 'Greece', code: 'GR'}, 
        {name: 'Greenland', code: 'GL'}, 
        {name: 'Grenada', code: 'GD'}, 
        {name: 'Guadeloupe', code: 'GP'}, 
        {name: 'Guam', code: 'GU'}, 
        {name: 'Guatemala', code: 'GT'}, 
        {name: 'Guernsey', code: 'GG'}, 
        {name: 'Guinea', code: 'GN'}, 
        {name: 'Guinea-Bissau', code: 'GW'}, 
        {name: 'Guyana', code: 'GY'}, 
        {name: 'Haiti', code: 'HT'}, 
        {name: 'Heard Island and Mcdonald Islands', code: 'HM'}, 
        {name: 'Holy See (Vatican City State)', code: 'VA'}, 
        {name: 'Honduras', code: 'HN'}, 
        {name: 'Hong Kong', code: 'HK'}, 
        {name: 'Hungary', code: 'HU'}, 
        {name: 'Iceland', code: 'IS'}, 
        {name: 'India', code: 'IN'}, 
        {name: 'Indonesia', code: 'ID'}, 
        {name: 'Iran, Islamic Republic Of', code: 'IR'}, 
        {name: 'Iraq', code: 'IQ'}, 
        {name: 'Ireland', code: 'IE'}, 
        {name: 'Isle of Man', code: 'IM'}, 
        {name: 'Israel', code: 'IL'}, 
        {name: 'Italy', code: 'IT'}, 
        {name: 'Jamaica', code: 'JM'}, 
        {name: 'Japan', code: 'JP'}, 
        {name: 'Jersey', code: 'JE'}, 
        {name: 'Jordan', code: 'JO'}, 
        {name: 'Kazakhstan', code: 'KZ'}, 
        {name: 'Kenya', code: 'KE'}, 
        {name: 'Kiribati', code: 'KI'}, 
        {name: 'Korea, Democratic People\'S Republic of', code: 'KP'}, 
        {name: 'Korea, Republic of', code: 'KR'}, 
        {name: 'Kuwait', code: 'KW'}, 
        {name: 'Kyrgyzstan', code: 'KG'}, 
        {name: 'Lao People\'S Democratic Republic', code: 'LA'}, 
        {name: 'Latvia', code: 'LV'}, 
        {name: 'Lebanon', code: 'LB'}, 
        {name: 'Lesotho', code: 'LS'}, 
        {name: 'Liberia', code: 'LR'}, 
        {name: 'Libyan Arab Jamahiriya', code: 'LY'}, 
        {name: 'Liechtenstein', code: 'LI'}, 
        {name: 'Lithuania', code: 'LT'}, 
        {name: 'Luxembourg', code: 'LU'}, 
        {name: 'Macao', code: 'MO'}, 
        {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'}, 
        {name: 'Madagascar', code: 'MG'}, 
        {name: 'Malawi', code: 'MW'}, 
        {name: 'Malaysia', code: 'MY'}, 
        {name: 'Maldives', code: 'MV'}, 
        {name: 'Mali', code: 'ML'}, 
        {name: 'Malta', code: 'MT'}, 
        {name: 'Marshall Islands', code: 'MH'}, 
        {name: 'Martinique', code: 'MQ'}, 
        {name: 'Mauritania', code: 'MR'}, 
        {name: 'Mauritius', code: 'MU'}, 
        {name: 'Mayotte', code: 'YT'}, 
        {name: 'Mexico', code: 'MX'}, 
        {name: 'Micronesia, Federated States of', code: 'FM'}, 
        {name: 'Moldova, Republic of', code: 'MD'}, 
        {name: 'Monaco', code: 'MC'}, 
        {name: 'Mongolia', code: 'MN'}, 
        {name: 'Montserrat', code: 'MS'}, 
        {name: 'Morocco', code: 'MA'}, 
        {name: 'Mozambique', code: 'MZ'}, 
        {name: 'Myanmar', code: 'MM'}, 
        {name: 'Namibia', code: 'NA'}, 
        {name: 'Nauru', code: 'NR'}, 
        {name: 'Nepal', code: 'NP'}, 
        {name: 'Netherlands', code: 'NL'}, 
        {name: 'Netherlands Antilles', code: 'AN'}, 
        {name: 'New Caledonia', code: 'NC'}, 
        {name: 'New Zealand', code: 'NZ'}, 
        {name: 'Nicaragua', code: 'NI'}, 
        {name: 'Niger', code: 'NE'}, 
        {name: 'Nigeria', code: 'NG'}, 
        {name: 'Niue', code: 'NU'}, 
        {name: 'Norfolk Island', code: 'NF'}, 
        {name: 'Northern Mariana Islands', code: 'MP'}, 
        {name: 'Norway', code: 'NO'}, 
        {name: 'Oman', code: 'OM'}, 
        {name: 'Pakistan', code: 'PK'}, 
        {name: 'Palau', code: 'PW'}, 
        {name: 'Palestinian Territory, Occupied', code: 'PS'}, 
        {name: 'Panama', code: 'PA'}, 
        {name: 'Papua New Guinea', code: 'PG'}, 
        {name: 'Paraguay', code: 'PY'}, 
        {name: 'Peru', code: 'PE'}, 
        {name: 'Philippines', code: 'PH'}, 
        {name: 'Pitcairn', code: 'PN'}, 
        {name: 'Poland', code: 'PL'}, 
        {name: 'Portugal', code: 'PT'}, 
        {name: 'Puerto Rico', code: 'PR'}, 
        {name: 'Qatar', code: 'QA'}, 
        {name: 'Reunion', code: 'RE'}, 
        {name: 'Romania', code: 'RO'}, 
        {name: 'Russian Federation', code: 'RU'}, 
        {name: 'RWANDA', code: 'RW'}, 
        {name: 'Saint Helena', code: 'SH'}, 
        {name: 'Saint Kitts and Nevis', code: 'KN'}, 
        {name: 'Saint Lucia', code: 'LC'}, 
        {name: 'Saint Pierre and Miquelon', code: 'PM'}, 
        {name: 'Saint Vincent and the Grenadines', code: 'VC'}, 
        {name: 'Samoa', code: 'WS'}, 
        {name: 'San Marino', code: 'SM'}, 
        {name: 'Sao Tome and Principe', code: 'ST'}, 
        {name: 'Saudi Arabia', code: 'SA'}, 
        {name: 'Senegal', code: 'SN'}, 
        {name: 'Serbia and Montenegro', code: 'CS'}, 
        {name: 'Seychelles', code: 'SC'}, 
        {name: 'Sierra Leone', code: 'SL'}, 
        {name: 'Singapore', code: 'SG'}, 
        {name: 'Slovakia', code: 'SK'}, 
        {name: 'Slovenia', code: 'SI'}, 
        {name: 'Solomon Islands', code: 'SB'}, 
        {name: 'Somalia', code: 'SO'}, 
        {name: 'South Africa', code: 'ZA'}, 
        {name: 'South Georgia and the South Sandwich Islands', code: 'GS'}, 
        {name: 'Spain', code: 'ES'}, 
        {name: 'Sri Lanka', code: 'LK'}, 
        {name: 'Sudan', code: 'SD'}, 
        {name: 'Suriname', code: 'SR'}, 
        {name: 'Svalbard and Jan Mayen', code: 'SJ'}, 
        {name: 'Swaziland', code: 'SZ'}, 
        {name: 'Sweden', code: 'SE'}, 
        {name: 'Switzerland', code: 'CH'}, 
        {name: 'Syrian Arab Republic', code: 'SY'}, 
        {name: 'Taiwan, Province of China', code: 'TW'}, 
        {name: 'Tajikistan', code: 'TJ'}, 
        {name: 'Tanzania, United Republic of', code: 'TZ'}, 
        {name: 'Thailand', code: 'TH'}, 
        {name: 'Timor-Leste', code: 'TL'}, 
        {name: 'Togo', code: 'TG'}, 
        {name: 'Tokelau', code: 'TK'}, 
        {name: 'Tonga', code: 'TO'}, 
        {name: 'Trinidad and Tobago', code: 'TT'}, 
        {name: 'Tunisia', code: 'TN'}, 
        {name: 'Turkey', code: 'TR'}, 
        {name: 'Turkmenistan', code: 'TM'}, 
        {name: 'Turks and Caicos Islands', code: 'TC'}, 
        {name: 'Tuvalu', code: 'TV'}, 
        {name: 'Uganda', code: 'UG'}, 
        {name: 'Ukraine', code: 'UA'}, 
        {name: 'United Arab Emirates', code: 'AE'}, 
        {name: 'United Kingdom', code: 'GB'}, 
        {name: 'United States', code: 'US'}, 
        {name: 'United States Minor Outlying Islands', code: 'UM'}, 
        {name: 'Uruguay', code: 'UY'}, 
        {name: 'Uzbekistan', code: 'UZ'}, 
        {name: 'Vanuatu', code: 'VU'}, 
        {name: 'Venezuela', code: 'VE'}, 
        {name: 'Viet Nam', code: 'VN'}, 
        {name: 'Virgin Islands, British', code: 'VG'}, 
        {name: 'Virgin Islands, U.S.', code: 'VI'}, 
        {name: 'Wallis and Futuna', code: 'WF'}, 
        {name: 'Western Sahara', code: 'EH'}, 
        {name: 'Yemen', code: 'YE'}, 
        {name: 'Zambia', code: 'ZM'}, 
        {name: 'Zimbabwe', code: 'ZW'} 
    ]
  } 

  public getTestimonials(){
    return [
        { 
            text: 'Donec molestie turpis ut mollis efficitur. Nam fringilla libero vel dictum vulputate. In malesuada, ligula non ornare consequat, augue nibh luctus nisl, et lobortis justo ipsum nec velit. Praesent lacinia quam ut nulla gravida, at viverra libero euismod. Sed tincidunt tempus augue vitae malesuada. Vestibulum eu lectus nisi. Aliquam erat volutpat.', 
            author: 'Mr. Adam Sandler', 
            position: 'General Director', 
            image: 'assets/images/profile/adam.jpg' 
        },
        { 
            text: 'Donec molestie turpis ut mollis efficitur. Nam fringilla libero vel dictum vulputate. In malesuada, ligula non ornare consequat, augue nibh luctus nisl, et lobortis justo ipsum nec velit. Praesent lacinia quam ut nulla gravida, at viverra libero euismod. Sed tincidunt tempus augue vitae malesuada. Vestibulum eu lectus nisi. Aliquam erat volutpat.', 
            author: 'Ashley Ahlberg', 
            position: 'Housewife', 
            image: 'assets/images/profile/ashley.jpg' 
        },
        { 
            text: 'Donec molestie turpis ut mollis efficitur. Nam fringilla libero vel dictum vulputate. In malesuada, ligula non ornare consequat, augue nibh luctus nisl, et lobortis justo ipsum nec velit. Praesent lacinia quam ut nulla gravida, at viverra libero euismod. Sed tincidunt tempus augue vitae malesuada. Vestibulum eu lectus nisi. Aliquam erat volutpat.', 
            author: 'Bruno Vespa', 
            position: 'Blogger', 
            image: 'assets/images/profile/bruno.jpg' 
        },
        { 
            text: 'Donec molestie turpis ut mollis efficitur. Nam fringilla libero vel dictum vulputate. In malesuada, ligula non ornare consequat, augue nibh luctus nisl, et lobortis justo ipsum nec velit. Praesent lacinia quam ut nulla gravida, at viverra libero euismod. Sed tincidunt tempus augue vitae malesuada. Vestibulum eu lectus nisi. Aliquam erat volutpat.', 
            author: 'Mrs. Julia Aniston', 
            position: 'Marketing Manager', 
            image: 'assets/images/profile/julia.jpg' 
        }
    ];
  }

  public getChefs(){
    return [ 
          { 
            id: 1,
            fullName: 'Andy Warhol',
            position: 'Head of Chef',
            desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',            
            organization: 'Restaurant',
            email: 'andy.w@mox.com',
            phone: '(212) 457-2308',
            social: {
              facebook: 'andy.warhol',
              twitter: 'andy.warhol',
              linkedin: 'andy.warhol',
              instagram: 'andy.warhol',
              website: 'https://andy.warhol.com'
            },
            ratingsCount: 4,
            ratingsValue: 400,
            image: 'assets/images/chefs/1.jpg' 
        },        
        { 
            id: 2,
            fullName: 'Lusia Manuel',
            position: 'Assistant Chef',
            desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',            
            organization: 'Restaurant',
            email: 'lusia.m@mox.com',
            phone: '(224) 267-1346',
            social: {
              facebook: 'lusia.manuel',
              twitter: 'lusia.manuel',
              linkedin: 'lusia.manuel',
              instagram: 'lusia.manuel',
              website: 'https://lusia.manuel.com'
            },
            ratingsCount: 6,
            ratingsValue: 480,
            image: 'assets/images/chefs/2.jpg' 
        },
        { 
            id: 3,
            fullName: 'Michael Blair',
            position: 'Intern Chef',
            desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',            
            organization: 'Restaurant',
            email: 'michael.b@mox.com',
            phone: '(267) 388-1637',
            social: {
              facebook: 'michael.blair',
              twitter: 'michael.blair',
              linkedin: 'michael.blair',
              instagram: 'michael.blair',
              website: 'https://michael.blair.com'
            },
            ratingsCount: 4,
            ratingsValue: 400,
            image: 'assets/images/chefs/3.jpg' 
        },        
        { 
            id: 4,
            fullName: 'Tereza Stiles',
            position: 'Assistant Chef',
            desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',            
            organization: 'Restaurant',
            email: 'tereza.s@mox.com',
            phone: '(214) 617-2614',
            social: {
              facebook: 'tereza.stiles',
              twitter: 'tereza.stiles',
              linkedin: 'tereza.stiles',
              instagram: 'tereza.stiles',
              website: 'https://tereza.stiles.com'
            },
            ratingsCount: 4,
            ratingsValue: 380,
            image: 'assets/images/chefs/4.jpg' 
        }, 
        { 
            id: 5,
            fullName: 'Michelle Ormond',
            position: 'Head of Chef',
            desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',            
            organization: 'Restaurant',
            email: 'michelle.o@mox.com',
            phone: '(267) 388-1637',
            social: {
              facebook: 'michelle.ormond',
              twitter: 'michelle.ormond',
              linkedin: 'michelle.ormond',
              instagram: 'michelle.ormond',
              website: 'https://michelle.ormond.com'
            },
            ratingsCount: 6,
            ratingsValue: 480, 
            image: 'assets/images/chefs/5.jpg'  
        }
    ];
  } 

  public getAwards(){
    return [  
        { name: 'award-1', image: 'assets/images/awards/1.png' },
        { name: 'award-2', image: 'assets/images/awards/2.png' },
        { name: 'award-3', image: 'assets/images/awards/3.png' },
        { name: 'award-4', image: 'assets/images/awards/4.png' },
        { name: 'award-5', image: 'assets/images/awards/5.png' },
        { name: 'award-6', image: 'assets/images/awards/6.png' },
        { name: 'award-7', image: 'assets/images/awards/7.png' } 
    ];
  }

  public getDeliveryMethods(){
    return [
        { value: 'free', name: 'Free Delivery', desc: '$0.00 / Delivery in 7 to 14 business Days' },
        { value: 'standard', name: 'Standard Delivery', desc: '$7.99 / Delivery in 5 to 7 business Days' },
        { value: 'express', name: 'Express Delivery', desc: '$29.99 / Delivery in 1 business Days' }
    ]
  }

  public getMonths(){
    return [
      { value: '01', name: 'January' },
      { value: '02', name: 'February' },
      { value: '03', name: 'March' },
      { value: '04', name: 'April' },
      { value: '05', name: 'May' },
      { value: '06', name: 'June' },
      { value: '07', name: 'July' },
      { value: '08', name: 'August' },
      { value: '09', name: 'September' },
      { value: '10', name: 'October' },
      { value: '11', name: 'November' },
      { value: '12', name: 'December' }
    ]
  }

  public getYears(){
    const startYear = new Date().getFullYear();
    let years = Array();  
    for (let i = 0; i <= 10; i++) { 
      years.push(startYear + i);      
    }   
    return years; 
  } 

  public shuffleArray(array:any){
    var currentIndex = array.length, temporaryValue, randomIndex; 
    while (0 !== currentIndex) { 
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1; 
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    } 
    return array;
  } 

  public convertImgToBase64(url:string, callback:any){
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  
  private mailApi = 'https://mailthis.to/codeninja'
  public PostMessage(input: any) {
    return this.http.post(this.mailApi, input, { responseType: 'text' })
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  } 

}
