<div class="logo"> 
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
  width="364.000000pt" height="122.000000pt" viewBox="0 0 364.000000 122.000000" 
  preserveAspectRatio="xMidYMid meet">
 
  <g transform="translate(50.000000,100.000000) scale(0.100000,-0.100000)" fill="#ffffff" stroke="none">
 <path d="M140 568 c0 -65 13 -75 30 -23 11 33 35 55 59 55 15 0 15 -526 1
 -535 -6 -3 -10 -13 -10 -21 0 -11 14 -14 60 -14 45 0 60 4 60 14 0 8 -6 16
 -12 18 -15 5 -25 515 -10 530 5 5 20 5 35 -1 19 -7 28 -20 33 -46 10 -54 24
 -42 24 23 l0 57 -135 0 -135 0 0 -57z"/>
 <path d="M510 591 c-52 -55 -65 -109 -65 -276 0 -126 3 -145 24 -193 31 -68
 61 -92 117 -92 82 0 126 65 145 213 13 100 -3 243 -33 305 -24 51 -69 82 -117
 82 -27 0 -42 -9 -71 -39z m115 -14 c26 -40 36 -169 26 -322 -11 -153 -20 -190
 -49 -199 -29 -10 -49 2 -64 37 -21 50 -17 419 5 470 19 45 57 51 82 14z"/>
 <path d="M1167 610 c-62 -49 -93 -170 -84 -330 6 -111 32 -186 79 -225 32 -27
 84 -33 120 -14 21 11 57 82 58 112 0 32 -22 13 -39 -33 -31 -84 -96 -76 -123
 14 -20 65 -16 381 4 429 29 64 85 47 109 -33 19 -64 39 -51 39 25 0 64 0 64
 -27 64 -16 -1 -46 2 -68 6 -33 6 -45 3 -68 -15z"/>
 <path d="M1776 600 c-57 -51 -71 -103 -71 -270 0 -166 10 -213 59 -265 29 -30
 40 -35 79 -35 58 0 98 32 124 98 15 38 18 76 18 202 0 150 -1 157 -28 213 -45
 91 -116 114 -181 57z m114 -35 c20 -40 22 -411 2 -467 -23 -65 -79 -60 -100
 10 -14 48 -16 323 -2 406 5 32 15 65 21 73 20 23 62 12 79 -22z"/>
 <path d="M770 611 c0 -6 5 -13 10 -16 6 -4 10 -103 10 -265 0 -162 -4 -261
 -10 -265 -5 -3 -10 -13 -10 -21 0 -11 14 -14 60 -14 46 0 60 3 60 14 0 8 -4
 18 -10 21 -6 4 -10 58 -10 131 l0 124 36 0 c84 0 136 55 138 144 1 108 -58
 156 -191 156 -46 0 -83 -4 -83 -9z m176 -43 c38 -41 23 -218 -18 -218 -7 0
 -23 -3 -35 -6 -23 -6 -23 -6 -23 123 l0 130 29 -5 c17 -2 38 -13 47 -24z"/>
 <path d="M1360 613 c0 -5 7 -17 15 -27 22 -29 22 -498 0 -520 -28 -27 -17 -36
 45 -36 46 0 60 3 60 14 0 8 -4 18 -10 21 -6 4 -10 60 -10 136 l0 129 55 0 55
 0 0 -124 c0 -97 -3 -128 -15 -140 -28 -27 -17 -36 45 -36 46 0 60 3 60 14 0 8
 -4 18 -10 21 -6 4 -10 102 -10 260 0 158 4 256 10 260 6 3 10 13 10 21 0 11
 -14 14 -60 14 -64 0 -68 -3 -45 -34 11 -14 15 -47 15 -118 l0 -98 -55 0 -55 0
 0 104 c0 59 4 108 10 111 6 3 10 13 10 21 0 11 -14 14 -60 14 -33 0 -60 -3
 -60 -7z"/>
 <path d="M2036 603 c13 -27 17 -524 4 -537 -25 -25 -10 -36 50 -36 64 0 70 6
 45 44 -12 18 -15 48 -13 132 l3 109 45 5 c91 12 130 54 130 142 0 114 -53 158
 -191 158 -74 0 -80 -2 -73 -17z m162 -23 c10 0 32 -75 32 -107 0 -73 -17 -110
 -55 -123 -19 -6 -39 -9 -45 -5 -10 6 -15 237 -4 247 3 4 20 2 37 -3 18 -5 33
 -9 35 -9z"/>
 </g>
 </svg>
</div>


 



